import React from "react"
import Layout, {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import Blocks from "../components/Page/Blocks"
import styled from "styled-components"
import InstagramLink from "../components/Core/InstagramLink"
//import ContactForm from "../components/Forms/ContactForm"
import colors from "../styles/colors"
import fonts from "../styles/fonts"
import breakpoints from "../styles/breakpoints"
import GetNotifiedBlock from "../components/Shop/GetNotifiedBlock"
import Hero from "../components/Blocks/Hero"
import Image from "../components/Blocks/Image"
import { Col, Row } from "styled-bootstrap-grid"
import Helmet from "react-helmet"


const ImageFormSubBlock = styled.div`

  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    
  }
`

const ImageWrapper = styled.div`
  border-radius: 0.25rem;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  padding-bottom: 75%;
`

// const Image = styled.img`
//   position: absolute;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   max-width: 100%;
//   display: block;
// `

const ImageBlock = styled.div`

  padding: 0 0 34px 0;
  @media (min-width: ${breakpoints.md}) {
    padding: 0;
    padding-right: 34px;
    width: 100%;
    heigh: 100%;
  }
  @media (min-width: ${breakpoints.lg}) {
    padding-right: 48px;
  }

`
const ContentSubBlock = styled.div`

  text-align: center;
  margin: auto;
  max-width: 100%;
  width: 100%;
  padding: 17px;
  
  @media (min-width: ${breakpoints.md}) {
    max-width: 67%;
    padding: 0;
    padding-top: 0;
  }

  h3 {
    color: ${colors.royalBlue};
    margin-bottom: 2rem;
    text-transform: uppercase;
    font-size: calc(0.6vw + 1rem);
    line-height: 1.176;
  }

  h2 {
    color: ${colors.royalBlue};
    margin-bottom: 2rem;
    line-height: 1.104;
  }

  p {
    b {
      i {
        ${fonts.workSansBold}
        font-style: italic;
        font-size: 1rem;
      }
    }
    margin-bottom: 34px;
  }
`


const Heading3 = styled.h3`
  ${fonts.workSansBold}
  color: ${colors.royalBlue};
  margin-top: 2rem;
  line-height: 1.176;
  padding-bottom: 34px;
`

const Heading2 = styled.h2`
  ${fonts.workSansBold}
  color: ${colors.royalBlue};
  margin-top: 2rem;
  line-height: 1.176;
  padding-bottom: 34px;
  
`

const PageContainer = styled.div`
  max-width: ${breakpoints.max};
  margin: auto;
  padding: ${`calc(${MOBILE_HEADER_HEIGHT}px + 5vw)`} 6vw 5vw;

  @media (min-width: ${breakpoints.md}) {
    padding: ${`calc(${DESKTOP_HEADER_HEIGHT}px + 5vw)`} 5vw 5vw;
  }
`

const FormContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    grid-column: 7 / 13;
    padding: 17px;
  }
`

const BlockContainer = styled.div`
  width: 500px;
 padding-right:05px;
  @media (min-width: ${breakpoints.md}) {
    grid-column: 1 ;
    padding: 17px;
   
  }
`
const BlockContainer2 = styled.div`
   width: 500px;
   margin-top: 30px;
 padding-left: 20px;
  @media (min-width: ${breakpoints.md}) {
    grid-column:  2;
    padding: 17px;
   
  }
  `
const SubscribeToCalendarPage = (props) => {
  const { location, data } = props
  const path = location.pathname

  const page = data.contentfulPage

  let sharingImage = false
  if (page.socialMediaImage) {
    sharingImage = page.socialMediaImage.file.url
  }

  const link = `
  <div style="margin-bottom:40px;">
  <a style="text-decoration: none; color: black" title="Add to Calendar" class="addeventstc" data-id="zA518682" href="https://www.addevent.com/calendar/zA518682" target="_blank" rel="nofollow">

Add to Calendar
  </a>
  <script type="text/javascript" src="https://cdn.addevent.com/libs/stc/1.0.2/stc.min.js" async defer></script>
  </div>
  `
  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo
        title={page.metaTitle}
        description={page.metaDescription}
        fullTitle={true}
        path={path}
        image={sharingImage}
      />
      <Helmet>
        
<script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=RxRBWG"></script>

      </Helmet>
      <PageContainer>
       <ContentSubBlock>
        <h2> {page.title} </h2>
        <p>Want in on the hottest dishes the moment they are released? We are always cooking up something new with our chef and restaurant partners! Each of our dishes is limited release, so subscribe to our Drop Calendar or Text Alerts and get a notified each time new dishes hit the shop.</p>
      </ContentSubBlock>
      
     <Row>
        <Col md={6} order-s-last >
        <ImageFormSubBlock>
         <ImageBlock>
          <Image image={page.blocks[0].image} />
        </ImageBlock>
        </ImageFormSubBlock>
    
        
       
	
  </Col>

  <Col md={6}>
  <h3>Subscribe To Our Drop Calendar</h3>
  <div dangerouslySetInnerHTML={{__html: link}} ></div>
  <h3>Request Text Notifications</h3>

  <div class="klaviyo-form-Tcqxfb"></div>
  </Col>
  </Row>
      </PageContainer>
    </Layout>
  )
}

export default SubscribeToCalendarPage

export const pageQuery = graphql`
  query SubscribeToCalendarQuery {
    contentfulPage(slug: { eq: "subscribe-to-calendar" }) {
      ...pageFragment
    }
  }
`
